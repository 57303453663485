
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import { apiAfterSaleList, apiAfterSaleOtherList } from '@/api/admin'
import { AfterSaleType } from '@/utils/type'
import AfterSalesPane from '@/components/order/after-sales-pane.vue'

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    AfterSalesPane,
    DatePicker
  }
})
export default class AdminAfterSales extends Vue {
  /** S Data **/
  activeName: any = 'all'
  activeStatus: any = ''
  tabs = [
    {
      label: '全部',
      name: AfterSaleType[0]
    },
    {
      label: '售后中',
      name: AfterSaleType[1]
    },
    {
      label: '售后成功',
      name: AfterSaleType[2]
    },
    {
      label: '售后失败',
      name: AfterSaleType[3]
    }
  ]

  tabCount = {
    all: 0, // 全部
    ing: 0, // 售后中
    success: 0, // 售后成功
    fail: 0 // 售后拒绝
  }

  form: any = {
    sid: '', // 否 string 售后单号
    after_sale_sn: '', // 否 string 售后单号
    order_sn: '', // 否 string 订单号
    user_info: '', // 否 string 用户编码
    goods_info: '', // 否 string 商品编码
    refund_type: '', // 否 int 售后类型 1-整单退款 2-商品售后
    refund_method: '', // 否 int 售后方式 1-仅退款 2-退货退款
    start_time: '', // 否 string 申请开始时间 格式：年月日 时：分：秒
    end_time: '', // 否 string 申请结束时间 格式：年月日 时：分：秒
    invoice_no: '' // 快递单号
  }

  otherLists: any = {
    platform_shop_list: []
  };

  pager: RequestPaging = new RequestPaging();

  /** E Data **/

  /** S Methods **/

  // 获取订单其他 方式数据
  getOtherList () {
    apiAfterSaleOtherList().then((res: any) => {
      this.otherLists = res
    })
  }

  // 获取列表
  getListInit (page?: number): void {
    const status: any = this.activeName === 'all' ? '' : AfterSaleType[this.activeName]
    this.activeStatus = status
    this.pager.request({
      callback: apiAfterSaleList,
      params: {
        status,
        ...this.form
      }
    }).then(res => {
      this.tabCount = res?.extend
    })
  }

  // 重置搜索
  reset (): void {
    Object.keys(this.form).map((key) => {
      this.$set(this.form, key, '')
    })
    this.getListInit()
  }
  /** E Methods **/

  /** S ods **/
  created () {
    this.getListInit()
    this.getOtherList()
  }
}
