import request from '@/plugins/axios'

/** 用户搜索条件列表 **/
export const apiUserSearchList = (params: any) => request.get('/admin.user/searchList', { params })
export const apiUserSearchShopList = () => request.get('/admin.user/searchShopList')
export const apiUserList = (params: any) => request.get('/admin.user/lists', { params })
export const apiUserOrderList = (params: any) => request.get('/admin.order/lists', { params })
export const apiOrderOtherList = () => request.get('/admin.order/otherLists')
export const apiAfterSaleList = (params: any) => request.get('/admin.after_sale/lists', { params })
export const apiAfterSaleOtherList = () => request.get('/admin.after_sale/otherLists')
export const apiUserEquityList = (params: any) => request.get('/admin.user/equityList', { params })
